import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";

const magicalPhrases = [
    "Conjuring Response...",
    "Weaving Wisdom...",
    "Spinning Sorcery...",
    "Summoning Insights...",
    "Crafting Enchantment...",
    "Brewing Understanding...",
    "Whisking Word Magic...",
    "Stirring the Oracle's Pot...",
    "Glimpsing Into the Crystal Ball...",
    "Unfurling Mystical Scripts...",
    "Igniting Knowledge Sparks...",
    "Casting Reply Spell...",
    "Invoking Idea Incantations...",
    "Interpreting Runes...",
    "Drawing from the Well of Wisdom...",
    "Transcribing from the Magic Tome...",
    "Unleashing Scroll of Clarity...",
    "Infusing Potion of Perception...",
    "Charming the Serpent of Wisdom...",
    "Channeling Arcane Intellect...",
  ];

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    const randomPhrase = magicalPhrases[Math.floor(Math.random() * magicalPhrases.length)];

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <AnswerIcon />
                <Stack.Item grow>
                    <p className={styles.answerText}>
                        {randomPhrase}
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
